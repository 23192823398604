<template>
  <div class="Promts__tabs">
    <v-tabs
        v-model="selectedTab">
      <v-tab
          v-for="tab in tabs"
          :key="tab.name"
          :disabled="tab.disabled">
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
        v-model="selectedTab"
        class="mt-6">
      <v-tab-item>
        <PromptTab/>
      </v-tab-item>
      <v-tab-item>
        <SystemPromptTab/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import PromptTab from "@/views/promts/tabs/prompt-tab.vue";
import SystemPromptTab from "@/views/promts/tabs/system-prompts-tab.vue";


export default {
  name: 'BrowserPromts',
  data() {
    return {
      tabs: [
        {
          name: 'Prompts'
        },
        {
          name: 'System Prompts'
        },
      ],
      selectedTab: Number(this.$route.query.selectedTab) || 0
    };
  },
  components: {
    PromptTab,
    SystemPromptTab
  }
};
</script> 

<style lang="scss" scoped>
@import './prompts-page';
</style>
